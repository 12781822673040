#map-canvas {
  height: 350px;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.bg_color_orange,
.bg_color_orange_hover:hover,
.p_table.bg_color_orange_hover.active,
[class*="button_type_"].color_orange:not(.transparent),
[class*="button_type_"].color_orange.transparent:hover{
	background:#ffb347;	
}

article.product-item:hover .product-quickview,
article.product-item:hover .product-image-2 {
  opacity: 0;
  display: none;
}

.widget-categories .widget-body > ul > li > a.no_sons:before {
  content: "";
  padding-left: 10px;
}

.ajax_loader,
.ajax_loader_trans {
  display: table;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999999;
  background: rgba(255, 255, 255, 1);
}

.ajax_loader_trans {
  background: rgba(255, 255, 255, 0.5);
}

.ajax_loader .middle,
.ajax_loader_trans .middle {
  display: table-cell;
  vertical-align: middle;
}

.ajax_loader .inner .spinner,
.ajax_loader_trans .inner .spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
}

.ajax_loader .inner .spinner .cube1,
.ajax_loader .inner .spinner .cube2,
.ajax_loader_trans .inner .spinner .cube1,
.ajax_loader_trans .inner .spinner .cube2 {
  background-color: #bf1f1f;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.ajax_loader .inner .spinner .cube2,
.ajax_loader_trans .inner .spinner .cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

article.product-item h3 {
  height: auto;
}

article.product-item:hover .product-overlay .product-mask {
  opacity: 0;
}

header.navbar li.navbar-search .dropdown-menu {
  border: 1px solid #eee;
  border-top: none;
}

.sitemap ul a:hover li {
  color: #bf1f1f;
}

.grandtotal {
  font-size: 19px;
  text-align: right;
  color: #bf1f1f;
  border-top: 1px solid #eeeeee;
  padding: 10px 0px;
}

.remove:hover {
  cursor: pointer;
}

input.has-error {
  border-color: #bf1f1f;
}
.error {
  color: #bf1f1f;
}
.small-products {
  background-color: #fff;
}
ins {
  text-decoration: none;
}
.form-group:nth-child(odd) {
  clear: both;
}
.form-group {
  margin-bottom: 5px;
}
.uppercase {
  text-transform: uppercase;
}
.uib-daypicker .btn-default,
.uib-daypicker .btn-default:hover,
.uib-daypicker .btn-default:focus {
  color: #777777;
}
.uib-daypicker .btn-default:hover,
.uib-daypicker .btn-default:focus {
  border-color: #bf1f1f;
}
.cal_btn,
.cal_btn:hover {
  color: #bf1f1f;
}
.red_bold {
  font-weight: bold;
  color: #bf1f1f;
}
.open-weekly-discount-list {
  color: #bf1f1f;
}
.open-weekly-discount-list:hover {
  cursor: pointer;
}
.clearfix {
  clear: both;
}
.table-products .form-control {
  width: 110%;
}
header.navbar-default {
  height: 87px;
}

header.navbar .navbar-brand {
  padding: 12px 15px;
}

header.navbar.navbar-small .navbar-brand {
  padding: 10px 15px;
}
.newsletter-badge {
  background-color: #bf1f1f;
  text-align: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 180px;
  height: 180px;
  color: #ffffff !important;
  padding-top: 26px;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.2);
}
.separator-newsletter .newsletter-badge span,
.featured-products .newsletter-badge span {
  font-size: 17px;
  color: #ffffff !important;
}
.sidebar .widget h3 a:after {
  content: none;
}
.blog article.post {
  padding: 30px 15px 25px 15px;
}
.blog article.post {
  margin-bottom: 0px;
}
#map-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
#products.list article.product-item .product-body {
  padding: 15px;
}
.service_block:nth-child(3n + 1) {
  clear: both;
}
.services .service {
  margin-bottom: 0px;
}
.service h3 {
  color: #bf1f1f !important;
}
.service i {
  color: #bf1f1f !important;
  border-color: #bf1f1f !important;
}
#quoteModal {
  margin-top: 150px;
}
footer li,
.copyright,
footer.navbar a {
  color: #b8b8b8 !important;
}
footer.navbar a {
  font-size: 16px;
}
footer.navbar a:hover {
  color: #bf1f1f !important;
}
.categories .category {
  /*background-color: rgba(191,31,31,0.7);*/
}
.category_block {
  margin-top: 15px;
}
.categories {
  padding-bottom: 15px;
}
.categories .category {
  display: table;
  overflow: hidden;
  background-color: transparent !important;
  width: 100%;
}
.categories .category > a {
  display: table-cell;
  vertical-align: bottom;
}
.categories .category .category-title {
  height: 82px;
  display: table-cell;
  vertical-align: middle;
  background-color: #c0272ae5;
  color: #ffffff;
  font-weight: 900;
  left: 0;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease-in 0s;
  width: 10%;
  position: relative !important;
  margin-top: -3px !important;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px;
  box-shadow: 10px 10px 18px #000000c5;
  box-sizing: border-box;
}
.categories .category > a > img {
  position: absolute;
  top: 0px;
  z-index: -1;
}

.categories .category .category-title {
  font-size: 42px;
}
.categories .category .category-mask {
  height: 72%;
}
footer h4 {
  color: #b8b8b8;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -1px;
}
footer p {
  color: #b8b8b8;
}
article.product-item .buttons {
  padding: 5px 0px 15px 0px;
}
.form_qty {
  width: 50px;
  float: left;
  margin-top: 15px;
}
.form_qty input {
  width: 50px;
  text-align: center;
}
.form_qty span {
  clear: both;
}
.cart_btn_category {
  margin-top: 15px;
  float: left;
}
input.error {
  border-color: #bf1f1f;
}
#products.grid article.product-item .product-body {
  padding: 0px 15px 15px 15px;
}
.grid .cart_btn_category {
  float: right;
  width: 120px;
  font-size: 12px;
}
.footer-widget-contacts ul li {
  font-size: 14px;
  text-align: left;
}
.footer-widget-contacts ul li,
.footer_block h4,
.footer_block p,
.footer_block label,
.footer_block form {
  text-align: left;
}
.footer_block i {
  display: inline-block;
  vertical-align: top;
}
header.navbar-default {
  background-color: #c0272a;
  z-index: 9999;
}
header.navbar .navbar-nav > li > a,
header.navbar .navbar-nav > li.dropdown.open > a,
header.navbar .navbar-nav > li > a:focus {
  color: #fff;
}
header.navbar .navbar-nav > li > a:hover {
  color: #fff;
}
header.navbar .dropdown-menu {
  margin-top: 12px;
}
header.navbar-default {
  border-color: #c0272a;
}
header.navbar .navbar-toggle:hover {
  color: #fff;
}
.top-header {
  height: auto;
}
header.navbar .navbar-collapse {
  margin: 17px 0;
}
.top-header .contacts li i {
  display: inline-block;
  vertical-align: middle;
}

.content h1.h2 {
  color: #666666;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: -1px;
  margin-top: 0;
}

.collapsed.no_sons > * {
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: 1px;
  text-decoration: none !important;
  letter-spacing: 0 !important;
}

.widget-categories .widget-body > ul > li {
  padding-top: 1px;
}
body {
  background-image: url("/assets/images/pattern_darker.png");
}
.categories_sidemenu_h2 {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0;
  display: inline-block;
}
.categories_sidemenu_h2:not(.collapsed) {
  color: #c0272a;
}
article.product-item.product-single .product-info li span {
  display: inline;
}
.stock_label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #c0272a;
  color: white;
  z-index: 2;
  padding: 5px;
}
.widget_title {
  color: #666666 !important;
  font-size: 32px !important;
  font-weight: 900;
  letter-spacing: -1px;
  margin-bottom: 10px;
}
.tabs.vertical-tabs .nav-tabs > li.active > a,
.tabs.vertical-tabs .nav-tabs > li.active > a:hover,
.tabs.vertical-tabs .nav-tabs > li.active > a:focus {
  background-color: #c0272a;
  color: #fff;
}
.slide-body {
  padding: 0 !important;
}
.notification p {
  color: #272727;
}
.callback_btn {
  background-color: #c0272a;
  color: #fff !important;
  border-radius: 5px;
  padding: 2px 7px;
  margin-left: 10px;
  display: inline-block !important;
}
.callback_btn:hover {
  cursor: pointer;
}
#callBackModal .modal-title {
  padding: 15px 27px;
}
.price_breaks_table tr td {
  padding: 3px 5px;
}
#printable_quote {
  display: none;
}
#logo_mobile,
.mobile_search_bar {
  display: none;
}
#mobile_cart {
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 86px;
  z-index: 5;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 5px 0px 5px 0px;
  display: none;
}
#mobile_cart ul li {
  list-style: none;
}
#mobile_cart ul {
  padding: 0px;
}
.gallery_image {
  margin-bottom: 15px;
}
.track_order_block {
  background-color: #bf1f1f;
  height: 35px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 7px;
  font-weight: 900;
}
.track_order_btn {
  padding: 10px 12px;
  background-color: #bf1f1f;
  border-color: #bf1f1f;
  color: white !important;
  border-radius: 4px;
  border: 2px;
}
.track_order_btn_margin {
  margin-left: -30px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.track_border_radius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.home_page_article_block {
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
}
.trackForm {
  padding: 10px;
  overflow: auto;
}
#track {
  border: 1px solid #bdc3c7;
  height: 40px;
  padding: 5px 15px;
  border-radius: 4px;
  text-transform: uppercase;
}

.trackCheckerBlock {
  height: 100%;
  padding: 20px 15px 20px 15px;
  background-color: rgba(164, 214, 5, 0.1);
  border: 1px solid #a4d605;
  border-top: none;
}
.track_find_btn {
  width: 10% !important;
  float: left;
  border: 1px solid #a4d605;
  background-color: #a4d605;
  color: #fff;
  font-size: 14px;
  height: 30px;
}
.trackCheckerBlock {
  height: 100%;
  padding: 20px 15px 20px 15px;
  background-color: rgba(164, 214, 5, 0.1);
  border: 1px solid #a4d605;
  border-top: none;
}
.trackCheckerBlock table tr td {
  border-top-color: #a4d605;
}

.trackCheckerBlock table thead tr th {
  border-bottom: 2px solid #a4d605;
}

#deliveryChecker {
  position: relative;
  overflow: hidden;
}

/*=============================================
=            Responsive Fixes                 =
=============================================*/

/******************* Xtra Xtra small *************/
@media screen and (max-width:768px){
  #logo {
    display: none;
  }
  #logo_mobile {
    display: block;
  }
}
@media screen and (max-width:1200px){
  header.navbar .navbar-nav > li > a{
    font-size: 16px !important;
  }
}

@media screen and (max-width: 499px) {
  article.product-item h3,
  article.product-item .price,
  article.product-item .price del + ins {
    font-size: 14px;
  }
  #products .item:nth-child(odd) {
    clear: left;
  }
  #products > div {
    width: 50% !important;
    float: left !important;
  }
  .grid .cart_btn_category {
    width: auto;
  }
  .footer_block {
    clear: both;
    margin-bottom: 25px;
  }
  .footer_block:first-child {
    margin-bottom: 5px;
  }
  .footer-widget {
    margin-bottom: 0px;
  }
  header.navbar .navbar-toggle,
  .navbar .navbar-toggle:focus {
    color: #fff;
  }
  header.navbar .navbar-nav > li > a,
  header.navbar .navbar-nav > li.dropdown.open > a,
  header.navbar .navbar-nav > li > a:focus,
  header.navbar .navbar-nav > li > a:hover {
    color: #777 !important;
  }
  header.navbar .dropdown-menu {
    margin-top: 0px;
  }
  .man_map,
  .tab-pane .newsletter-badge {
    display: none;
  }
  .categories .category .category-title {
    font-size: 29px;
  }
  .categories .category {
    height: 100px;
  }
  .topbar-section {
    width: 100%;
    text-align: center;
  }
  .navbar-header a {
    width: 76%;
  }
  .home_page_article {
    clear: both;
  }
  #logo {
    display: none;
  }
  #logo_mobile {
    display: block;
  }
  .list-inline.contacts.email {
    display: none;
  }
  .navbar-header a {
    width: auto;
  }
  .mobile_search_bar {
    width: 100%;
    height: 80px;
    background-color: #fff;
    position: absolute;
    top: 80px;
    padding: 20px;
  }
  .mobile_search_bar input {
    width: 75%;
    height: 40px;
    float: left;
  }
  .mobile_search_bar button {
    float: right;
    height: 40px;
  }
  .top-header {
    position: fixed;
    z-index: 9999;
    width: 100%;
  }
  .navbar-static-top {
    position: fixed;
    top: 37px;
    width: 100%;
  }
  .jumbotron {
    display: none;
  }
  .featured-products .container {
    padding-top: 110px;
  }
  #logo_mobile {
    width: 75%;
  }
  header.navbar .navbar-toggle {
    font-size: 16px;
    margin: 0;
  }
  header.navbar-default {
    height: 70px;
  }
  .text-right.topbar-section .contacts li {
    font-size: 14px !important;
  }
  #mobile_cart {
    top: 70px;
  }
  #mobile_cart h4,
  #mobile_cart p {
    margin: 0px;
  }
  .breadcrumb-wrapper {
    padding: 120px 0px 0px 0px;
  }
  .mobile_search_bar {
    top: 70px;
  }
  .mobile_search_bar input {
    width: 70%;
  }
  .navbar-collapse {
    margin: 2px 0px 0px 0px !important;
  }
  .overlay {
    content: "";
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background: #000;
    opacity: .2;
    filter: alpha(opacity=20);
    cursor: pointer;
  }
}

/****************** xtra small ***************/

@media (min-width: 500px) and (max-width: 767px) {
  .item:nth-child(2n + 1) {
    clear: both;
  }
  .footer_block {
    clear: both;
    margin-bottom: 25px;
  }
  .footer_block:first-child {
    margin-bottom: 5px;
  }
  .footer-widget {
    margin-bottom: 0px;
  }
  header.navbar .navbar-toggle,
  .navbar .navbar-toggle:focus {
    color: #fff;
  }
  header.navbar .navbar-nav > li > a,
  header.navbar .navbar-nav > li.dropdown.open > a,
  header.navbar .navbar-nav > li > a:focus,
  header.navbar .navbar-nav > li > a:hover {
    color: #777 !important;
  }
  header.navbar .dropdown-menu {
    margin-top: 0px;
  }
  .man_map,
  .tab-pane .newsletter-badge {
    display: none;
  }
  .categories .category .category-title {
    font-size: 30px;
  }
  .categories .category {
    height: 240px;
  }
  .topbar-section {
    width: 100%;
    text-align: center;
  }
  .home_page_article {
    clear: both;
  }
}
@media (min-width: 600px) and (max-width: 700px) {
  .categories .category .category-title {
    font-size: 30px;
  }
  .categories .category {
    height: 210px;
  }
}
@media (min-width: 500px) and (max-width: 600px) {
  .categories .category .category-title {
    font-size: 30px;
  }
  .categories .category {
    height: 180px;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .categories .category .category-title {
    font-size: 18px;
  }
  .categories .category {
    height: 140px;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .categories .category .category-title {
    font-size: 18px;
  }
  .categories .category {
    height: 100px;
  }
}
@media (min-width: 300px) and (max-width: 350px) {
  .categories .category .category-title {
    font-size: 16px;
  }
  .categories .category {
    height: 75px;
  }
}

/********************* small **************/

@media (min-width: 768px) and (max-width: 991px) {
  .item:nth-child(2n + 1) {
    clear: both;
  }
  .cart_btn_category {
    padding: 6px 12px !important;
    font-size: 14px !important;
  }
  .footer-widget-contacts ul li i {
    display: inline-block;
  }
  .navbar-header {
    width: 30%;
    padding-top: 10px;
  }
  .home_page_article {
    clear: both;
  }
  .tab-pane .newsletter-badge {
    display: none;
  }
  .categories .category {
    height: 240px;
  }
  .categories .category .category-title {
    font-size: 35px;
  }
}

/**************** medium ****************/

@media (min-width: 992px) and (max-width: 1199px) {
  .item:nth-child(3n + 1) {
    clear: both;
  }
  .categories .category {
    height: 240px;
  }
  .categories .category .category-title {
    font-size: 28px;
  }
}

/************ Large **************/

@media (min-width: 1200px) {
  .item:nth-child(3n + 1) {
    clear: both;
  }
  .categories .category {
    height: 240px;
  }
  .categories .category .category-title {
    font-size: 28px;
  }
}

/*# sourceMappingURL=custom.css.map */
@media (min-width: 768px) and (max-width: 991px) {
  #custom-nav-link {
    padding: 15px 5px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", Verdana, Geneva, sans-serif !important;
}
@media (min-width: 1200px) {
  .div-recaptcha iframe {
    margin-left: 65px;
  }
}
.div-recaptcha iframe {
  max-width: 295px;
}
.send_msg_btn_div {
  margin-top: 10px;
}
.send_msg_btn {
  padding-left: 20px;
  padding-right: 20px;
}
